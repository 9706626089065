<template>
  <div id="tosf-application-form" class="">
    <div class="c-app justify-content-center mt-3">
      <div>
        <div class="mb-4">
          <div class="text-center mb-3">
            <div class=" ">
              <img
                src="@/assets/images/ched-header-logo-cropped.png"
                class="mb-3"
              />
            </div>

            <h4 class=" mb-1">
              CHED CARAGA
            </h4>
            <h3 class="font-weight-bold">
              TUITION AND OTHER SCHOOL FEES INCREASE
            </h3>
            <h3></h3>
          </div>
        </div>
        <div class="card rounded shadow-sm">
          <div class="card-body overflow-auto" style="width: 600px;">
            <section v-if="currentStep == 1">
              <div class="">
                <h5 class="font-weight-bold text-center">
                  Please read the instructions carefully.
                </h5>
                <ol class="mt-3">
                  <li>
                    Make sure that all files must be submitted in
                    <strong class="">PDF format</strong>. Double check all
                    documents before submitting.
                  </li>
                  <li>
                    Please ensure that you submit
                    <strong>only one file for each area</strong>. In case you
                    have multiple documents, please merge them into a single
                    file before uploading.
                  </li>
                  <li>
                    It is crucial to thoroughly review and verify all the
                    information before proceeding with the submission.
                  </li>
                </ol>

                <div class="form-check text-center">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="checkbox"
                    v-model="checkbox"
                  />
                  <label
                    class="form-check-label"
                    for="checkbox"
                    style="font-size: 15px;"
                    >I have read and understood the instructions.</label
                  >
                </div>
                <div class="text-center mt-3">
                  <button
                    :disabled="!checkbox"
                    class="btn btn-lg btn-primary"
                    @click="getNextId"
                  >
                    Get started
                  </button>

                  <!-- Add search section -->
                  <div class="mt-4">
                    <h6 class="mb-3">Have an existing application?</h6>
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="form-group mb-0 mr-2">
                        <input
                          type="text"
                          v-model="searchAppNo"
                          class="form-control in-search"
                          placeholder="Enter App No. (e.g. CHED13-TOSF-2025-123)"
                          @keyup.enter="searchApplication"
                        />
                      </div>
                      <button 
                        class="btn btn-secondary"
                        @click="searchApplication"
                        :disabled="!searchAppNo || searching"
                      >
                        <i class="bi bi-search mr-1"></i>
                        {{ searching ? 'Searching...' : 'Search' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section v-if="currentStep == 2">
              <div>
                <h5 class="font-weight-bold">Application Details</h5>
                <p v-if="form.id">
                  App No:
                  <strong>CHED13-TOSF-{{ form.id }}</strong>
                </p>

                <div class="form-group">
                  <label for=""><span class="text-danger">*</span>Name of HEI</label>
                  <select name="" v-model="hei" class="form-control" id="">
                    <option value="" selected disabled hidden>Choose</option>
                    <option v-for="hei in heis" :value="hei" :key="hei.code">{{
                      hei.name
                    }}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for=""><span class="text-danger">*</span>Contact Person</label>
                  <input type="text" v-model="form.contact_person" class="form-control" required/>
                </div>

                <div class="form-group">
                  <label for=""><span class="text-danger">*</span>Email</label>
                  <input 
                    type="email" 
                    v-model="form.email" 
                    class="form-control" 
                    :class="{ 'is-invalid': !isValidEmail && form.email }"
                    required
                  />
                  <div class="invalid-feedback" v-if="!isValidEmail && form.email">
                    Please enter a valid email address
                  </div>
                </div>

                <div class="form-group">
                  <label for=""><span class="text-danger">*</span>Phone No.</label>
                  <input 
                    type="text" 
                    v-model="form.phone_no" 
                    class="form-control" 
                    :class="{ 'is-invalid': !isValidPhone && form.phone_no }"
                    maxlength="11"
                    @input="formatPhoneNumber"
                    required
                  />
                  <div class="invalid-feedback" v-if="!isValidPhone && form.phone_no">
                    Please enter a valid 11-digit phone number starting with 0
                  </div>
                </div>

                <div class="row">
                  <div class="col-auto">
                    <div class="form-group">
                      <label for=""><span class="text-danger">*</span>A.Y. Effectivity</label>
                      <select
                        v-model="form.academic_year"
                        name=""
                        class="form-control"
                        id=""
                      >
                        <option value="" selected disabled hidden
                          >Choose</option
                        >
                        <option
                          v-for="ay in academicYears"
                          :key="ay"
                          :value="ay"
                          >{{ ay }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>

                <div> 
                  <label for=""><span class="text-danger">*</span>Type of Increase</label>
                  <div class="form-group mt-2">
                    <input type="radio" id="1st_year" name="1st_year" v-model="year_level" value="firstYear">
                    <label for="1st_year" class="ml-2"> First Year Level</label><br>
                  </div>

                  <div class="form-group">
                    <input type="radio" id="all_year" name="all_year" v-model="year_level" value="allYear">
                    <label for="all_year" class="ml-2"> All Year Level</label><br>
                  </div>
                </div>

              </div>
            </section>

            <section v-if="year_level == 'allYear'">
              <div v-for="(req, index) in requirements" :key="req.id"> 
                <div class="" v-if="currentStep == 3 + index">
                <h5 class="text-center">
                  Requirement {{ index + 1 }} of {{ requirements.length }}
                </h5>
                <p
                  style="white-space: pre-wrap; text-indent: -4px; font-size: 15px;"
                  class="mt-3"
                >
                  {{ req.description }}
                </p>
                <div class="form-group mt-5">
                  <label for="">
                    Please upload the required document.
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="application/pdf"
                    class="form-control-file"
                    @change="uploadFileEvent($event, index, req)"
                  />
                  <small class="form-text text-muted">
                    <i class="bi bi-exclamation-triangle-fill mx-1"></i> 
                    Make sure to upload the <strong>PDF file</strong> only.
                  </small>
                </div>

                <!-- Excel Upload - Only show for requirement ID 5 -->
                <div v-if="editFlag ? req.requirement_id === 5 : req.id === 5" class="form-group mt-3">
                  <label for="">
                    Please upload the Excel file.
                  </label>
                  <input
                    id="excel-upload"
                    type="file"
                    accept=".xlsx, .xls"
                    class="form-control-file"
                    @change="uploadExcelEvent($event, index, req)"
                  />
                  <small class="form-text text-muted">
                    <i class="bi bi-exclamation-triangle-fill mx-1"></i> 
                    Make sure to upload the <strong>Excel file</strong> only.
                  </small>
                  
                </div>

              

                <div v-if="fileUploadLoader" class="d-flex align-items-center">
                  <strong class="text-primary">{{ loaderMsg }}</strong>
                  <div
                    class="spinner-border text-primary spinner-border-sm ml-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>

                <div v-if="req.filename" class="">
                  <a
                    href="#"
                    target="_blank"
                    @click.prevent="openUrl(req.path)"
                  >
                    <i class="bi bi-file-pdf"></i> {{ req.filename }}
                    <i class="bi bi-check-circle-fill text-success mx-1"></i
                  ></a>
                  <br>
                  <small class="text-danger" v-if="req.file_remarks">
                    Remarks: {{ req.file_remarks }}
                  </small>
                </div>
                <!-- Show Excel file info if uploaded -->
                <div v-if="editFlag ? (req.requirement_id === 5 && req.filename) : (req.id === 5 && req.excelFilename)" class="mt-2">
                  <a
                    href="#"
                    target="_blank"
                    @click.prevent="openUrl(editFlag ? req.excel_path : req.excelPath)"
                  >
                    <i class="bi bi-file-excel"></i> {{ editFlag ? req.excel_filename : req.excelFilename }}
                    <i class="bi bi-check-circle-fill text-success mx-1"></i
                  ></a>
                </div>
                </div>
              </div>
            </section>

            <section v-if="year_level == 'firstYear'">
              <div v-for="(req, index) in requirementsF" :key="req.id"> 
                <div class="" v-if="currentStep == 3 + index">
                <h5 class="text-center">
                  Requirement {{ index + 1 }} of {{ requirementsF.length }}
                </h5>
                <p
                  style="white-space: pre-wrap; text-indent: -4px; font-size: 15px;"
                  class="mt-3"
                >
                  {{ req.description }}

                </p>
                <div class="form-group mt-5">
                  <label for="">
                    Please upload the required document.
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="application/pdf"
                    class="form-control-file"
                    @change="uploadFileEvent($event, index, req)"
                  />
                  <small class="form-text text-muted">
                    <i class="bi bi-exclamation-triangle-fill mx-1"></i> 
                    Make sure to upload the <strong>PDF file</strong> only.
                  </small>
                </div>

                <div v-if="editFlag ? req.requirement_id === 12 : req.id === 12" class="form-group mt-3">
                  <label for="">
                    Please upload the Excel file.
                  </label>
                  <input
                    id="excel-upload"
                    type="file"
                    accept=".xlsx, .xls"
                    class="form-control-file"
                    @change="uploadExcelEvent($event, index, req)"
                  />
                  <small class="form-text text-muted">
                    <i class="bi bi-exclamation-triangle-fill mx-1"></i> 
                    Make sure to upload the <strong>Excel file</strong> only.
                  </small>
                </div>

                <div v-if="fileUploadLoader" class="d-flex align-items-center">
                  <strong class="text-primary">{{ loaderMsg }}</strong>
                  <div
                    class="spinner-border text-primary spinner-border-sm ml-auto"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>

                <div v-if="req.filename" class="">
                  <a
                    href="#"
                    target="_blank"
                    @click.prevent="openUrl(editFlag ? req.updated_path : req.path)"
                  >
                    <i class="bi bi-file-pdf"></i> {{ req.filename }}
                    <i class="bi bi-check-circle-fill text-success mx-1"></i
                  ></a>
                  
                  <br>
                  <small class="text-danger" v-if="req.file_remarks">
                    Remarks: {{ req.file_remarks }}
                  </small>
                </div>

                <!-- Show Excel file info if uploaded -->
                <div v-if="editFlag ? (req.requirement_id === 12 && req.filename) : (req.id === 12 && req.excelFilename)" class="mt-2">
                  <a
                    href="#"
                    target="_blank"
                    @click.prevent="openUrl(editFlag ? req.excel_path : req.excelPath)"
                  >
                  
                    <i class="bi bi-file-excel"></i> {{ editFlag ? req.excel_filename : req.excelFilename }}
                    <i class="bi bi-check-circle-fill text-success mx-1"></i
                  ></a>
                </div>
              </div>
              </div>
            </section>


            <section>
              <div v-if="year_level == 'firstYear' ? currentStep == 3 + requirementsF.length : currentStep == 3 + requirements.length">
                <h5 class="text-center mb-3">
                  Review your application before submitting
                </h5>
                <table class="table table-bordered table-sm">
                  <tbody>
                    <tr>
                      <td width="45%">Name of HEI</td>
                      <th>{{ hei.name }}</th>
                    </tr>
                    <tr>
                      <td>A.Y. Effectivity</td>
                      <th>{{ form.academic_year }}</th>
                    </tr>
                    <tr>
                      <th colspan="2">Documentary Requirements</th>
                    </tr>
                    <tr v-for="(req, index) in year_level == 'firstYear' ? requirementsF : requirements" :key="index">
                      <td width="60%">
                        {{ `${index + 1}. ${req.description} ` }}
                      </td>
                      <td>
                        <a
                          href=""
                          target="_blank"
                          @click.prevent="openUrl(req.path)"
                        >
                          <i class="bi bi-file-pdf mr-1"></i>
                          {{ req.filename }}</a
                        >
                        <br>
                        <br>
                        <br>
                        <br>
                        <a
                          class="mt-4"
                          href=""
                          target="_blank"
                          @click.prevent="openUrl(req.excelPath)"
                          v-if="req.id == 12 || req.id == 5"
                        >
                          <i class="bi bi-file-pdf mr-1"></i>
                          {{ req.excelFilename }}</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section v-if="year_level == 'firstYear' ? currentStep == 4 + requirementsF.length : currentStep == 4 + requirements.length">
              <div class="content">
                  <div class="d-flex justify-content-end mb-3">
                    <button class="btn btn-outline-primary" @click="printAcknowledgment">
                      <i class="bi bi-printer mr-1"></i>
                      Print
                    </button>
                  </div>
                  <p><strong>The President/Head of the Institution</strong></p>
                  <p><strong>{{ dateSubmitted }}</strong></p>
                  <p>The President/Head of the Institution</p>
                  <p>Dear Mr./Ms. President:</p>
                  <p>
                      This is to acknowledge receipt of your application for the Increase in Tuition and Other School Fees (TOSF) for the academic year <strong>{{ form.academic_year }}</strong> with Application No. <strong>CHED13-TOSF-{{ form.id }} </strong> . Your application has been successfully submitted to our office and is currently under review.
                  </p>
                  <p>
                      Should any additional information or documentation be required, we will notify you accordingly.
                  </p>
                  <p>
                      For inquiries, you may contact Mr. Ever Joy G. Rabadan, MAEM, MSc., Education Supervisor II, at 0908-867-1550 or through email at <a href="mailto:ejrabadan@ched.gov.ph">ejrabadan@ched.gov.ph</a>. Thank you.
                  </p>
              </div>

            <div class="footer">
                <p>Best Regards,</p>
                <p>CHED Caraga</p>
            </div>

            </section>
          </div>
          <!-- end of card body -->
        </div>
        <div
          v-if="year_level == 'firstYear' ? currentStep > 1 && currentStep != 4 + requirementsF.length : currentStep > 1 && currentStep != 4 + requirements.length"
          class="d-flex justify-content-between mb-3"
        >
          <button class="btn btn-secondary" @click="prevStep">
            Previous
          </button>
          <button
            v-if="year_level == 'firstYear' ? currentStep != 1 && currentStep < 3 + requirementsF.length : currentStep != 1 && currentStep < 3 + requirements.length"
            :disabled="isDisabled"
            class="btn btn-primary"
            @click="nextStep"
          >
            Next
          </button>
          <button
            v-else
            class="btn btn-primary"
            @click="submit"
            :disabled="isSubmitBtnDisabled"
          >
            Submit
          </button>
        </div>

        <div class="text-center mt-3">
          <button
            v-if="submitAnotherEntry"
            @click.prevent="resetForm"
            class="btn btn-lg btn-primary"
          >
            Submit another entry
          </button>
        </div>
      </div>
    </div>

    <!-- Add this confirmation modal -->
    <div 
      class="modal fade" 
      id="submitConfirmModal" 
      tabindex="-1" 
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Submission</h5>
            <button 
              type="button" 
              class="close" 
              data-dismiss="modal"
              aria-label="Close"
            >
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="mb-0">Are you sure you want to submit this application?</p>
          </div>
          <div class="modal-footer">
            <button 
              type="button" 
              class="btn btn-secondary" 
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button 
              type="button" 
              class="btn btn-danger" 
              @click="confirmSubmit"
              :disabled="isSubmitBtnDisabled"
            >
              {{ isSubmitBtnDisabled ? 'Submitting...' : 'Yes, Submit Application' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { awardYears } from '../../js/award-years';

export default {
  name: 'TosfApplicationForm',
  data() {
    return {
      academicYears: awardYears,
      loaderMsg:
        'Uploading file. This may take a while. Do not refresh or close this page...',
      getStarted: false,
      editFlag: false,
      heis: [],
      checkbox: false,
      currentStep: 1,
      requirements: [],
      requirementsF: [],
      temp_requirementsF: [],
      fileUploadLoader: false,
      file: '',
      form: new Form({
        hei_code: '',
        academic_year: '',
        email: '',
        contact_person: '',
        phone_no: '',
        type_increase: '',
      }),
      year_level: '',
      prefix: 'https://drive.google.com/file/d/',
      hasSubmittedSuccessfully: false,
      submitAnotherEntry: false,
      isDisabled: true,
      isSubmitBtnDisabled: false,
      hei: '',
      program: '',
      searchAppNo: '',
      searching: false,
      dateSubmitted: ''
    };
  },

  methods: {
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
    printAcknowledgment() {
      window.print();
    },
    clearAttachments() {
      this.attachments = [];
      let el = document.getElementById('file-upload');
      el.value = '';
    },

    generateApplicationId() {
      const currentYear = new Date().getFullYear();
      // Generate random number between 100-999
      const randomNum = Math.floor(Math.random() * 900) + 100;
      // Format: YYYY-XXX (e.g., 2025-123)
      return `${currentYear}-${randomNum}`;
    },

    getNextId() {
      this.getStarted = true;
      // Generate and set the application ID
      this.form.id = this.generateApplicationId();
      this.nextStep();
      this.getRequirements();
      this.getRequirementsFirst();
    },

    uploadFileEvent(event, index, req) {
      this.file = event.target.files[0];
      this.uploadFileToGoogleDrive(index, req);
    },

    uploadFileToGoogleDrive(index, req) {
      let fd = new FormData();
      this.fileUploadLoader = true;

      fd.append('file', this.file);
      fd.append('requirement_id', this.editFlag ? req.requirement_id : req.id);
      fd.append('id', this.form.id);

      $('#progress-bar-modal').modal({
        show: true,
        keyboard: false,
        backdrop: 'static',
      });

      axios
        .post(this.editFlag ? `api/tosf/documentary-requirements/file-upload-update` : 'api/tosf/documentary-requirements/file-upload', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          $('#progress-bar-modal').modal('hide');
          if(this.year_level == 'firstYear') {
            this.requirementsF[index].filename = this.editFlag ? res.data?.data?.filename : res.data?.filename;
            this.requirementsF[index].path = this.editFlag ? res.data?.data?.path : res.data?.path;
            this.isValidatedF();

          } else {
            this.requirements[index].filename = this.editFlag ? res.data?.data?.filename : res.data?.filename;
            this.requirements[index].path = this.editFlag ? res.data?.data?.path : res.data?.path;
            this.isValidated();

          }
          document.getElementById('file-upload').value = '';
          this.file = '';
          this.fileUploadLoader = !this.fileUploadLoader;
          this.refreshApplication();
        })
        .catch((error) => {
          $('#progress-bar-modal').modal('hide');
          toastr.error(error.response.data.message, 'Error');
        });
    },

    nextStep() {
      if(this.year_level == 'firstYear') {
        if(this.editFlag) {
            this.currentStep++;
            this.requirementsF = this.temp_requirementsF;
        } else {
          if (this.isValidatedF()) {
            this.currentStep++;
          }
        }
      } else {
        if(this.editFlag) {
          this.currentStep++;
          this.requirements = this.temp_requirementsF;
        } else {
          if (this.isValidated()) {
            this.currentStep++;
          }
        }
      }

    },
    prevStep() {
      this.currentStep--;
    },

    enableNext() {
      if (this.currentStep == 2 && this.requirements.length != 0) {
        this.isDisabled = false;
      }
    },
    enableNextF() {
      if (this.currentStep == 2 && this.requirementsF.length != 0) {
        this.isDisabled = false;
      }
    },

    isValidated() {

      if (this.currentStep == 1) {
        return true;
      }

      if ((this.currentStep == 2 && this.requirements.length != 0)) {
        if (!this.isAppDetailsValidated()) {
          toastr.error('Please fill in all required fields.', 'Fields marked with a red asterisk are required');
          return false;
        }
        return true;
      }

      if (this.currentStep <= 2 + this.requirements.length) {
        if (this.requirements[this.currentStep - 3].filename == '') {
          toastr.error(
            'Please upload the required document.',
            'Required field'
          );
          return false;
        } else {
          return true;
        }
      }

      if (this.currentStep > 2 + this.requirements.length) {
        return true;
      }
    },
    isValidatedF() {
      if (this.currentStep == 1) {
        return true;
      }
      
      if (this.currentStep == 5 || this.currentStep == 12) {
        // Check if a file is selected
        if (!this.file1) {
          toastr.error(
            'Please upload the required document.',
            'Required field'
          );
          return;
        }

      }

      if ((this.currentStep == 2 && this.requirementsF.length != 0)) {
        if (!this.isAppDetailsValidated()) {
          toastr.error('Please fill in all required fields.', 'Fields marked with a red asterisk are required');
          return false;
        }
        return true;
      }

      if (this.currentStep <= 2 + this.requirementsF.length) {
        if (this.requirementsF[this.currentStep - 3].filename == '') {
          toastr.error(
            'Please upload the required document.',
            'Required field'
          );
          return false;
        } else {
          return true;
        }
      }

      if (this.currentStep > 2 + this.requirementsF.length) {
        return true;
      }
    },

    isAppDetailsValidated() {
      if (
        this.form.academic_year == '' || 
        this.hei.code == '' || 
        this.form.contact_person == '' || 
        !this.isValidEmail || 
        !this.isValidPhone || 
        this.year_level == ''
      ) {
        return false;
      }
      return true;
    },

    getRequirements() {
      axios
        .get('api/tosf/requirements')
        .then((res) => {
          this.requirements = res.data.map((obj) => ({
            ...obj,
            filename: '',
            path: ''
          }));
          this.enableNext();
        })
        .catch((err) => {
          console.error('Error getting requirements:', err);
        });
    },

    
    getRequirementsFirst() {
      axios
        .get('api/tosf/requirements-first')
        .then((res) => {
          this.requirementsF = res.data.map((obj) => ({
            ...obj,
            filename: '',
            path: '',
            excelFilename: '',  // Add Excel properties
            excelPath: ''
          }));
          this.enableNextF();
        })
        .catch((err) => {
          console.error('Error getting requirements:', err);
        });
    },

    submit() {
      // Show the confirmation modal instead of using confirm()
      $('#submitConfirmModal').modal('show');
    },

    confirmSubmit() {
      this.isSubmitBtnDisabled = true;
      this.loaderMsg = 'Submitting your application...';
      this.fileUploadLoader = true;
      this.form.hei_code = this.hei.code;
      this.form.type_increase = this.year_level;
      
      // Hide the modal
      $('#submitConfirmModal').modal('hide');

      axios
        .post(this.editFlag ? `api/tosf/application-update` : 'api/tosf/application', this.form)
        .then((res) => {
          this.nextStep();
          this.submitAnotherEntry = true;
          this.fileUploadLoader = false;
          this.isSubmitBtnDisabled = false;
        })
        .catch((err) => {
          toastr.error('Oops! Unable to submit the application.', 'Error');
          this.isSubmitBtnDisabled = false;
          this.fileUploadLoader = false;
        });
    },

    getHeis() {
      axios
        .get('api/heis')
        .then((response) => {
          // Filter to only include HEIs with status "Active"
          this.heis = response.data.filter(hei => hei.status === "Active");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resetForm() {
      this.loaderMsg =
        'Uploading file. This may take a while. Do not refresh or close this page...';
      this.form.reset();
      this.file = '';
      this.currentStep = 1;
      this.hei = '';
      this.submitAnotherEntry = false;
    },

    hasExcelFile(req) {
      return (req.id === 5 || req.id === 12) && req.excelFilename && req.excelPath;
    },

    uploadExcelEvent(event, index, req) {
      this.file1 = event.target.files[0];

      this.uploadExcelToServer(index, req);
    },

    uploadExcelToServer(index, req) {
      let fd = new FormData();
      this.fileUploadLoader = true;
      this.loaderMsg = 'Uploading Excel file. Please wait...';

      fd.append('file', this.file1);
      fd.append('requirement_id', this.editFlag ? req.requirement_id : req.id);
      fd.append('id', this.form.id);

      $('#progress-bar-modal').modal({
        show: true,
        keyboard: false,
        backdrop: 'static',
      });

      axios
        .post(this.editFlag ? 'api/tosf/documentary-requirements/excel-upload-update' : 'api/tosf/documentary-requirements/excel-upload', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log('CHECK DATA', res);
          $('#progress-bar-modal').modal('hide');
          
          // Update the appropriate requirements array based on year level
          if (this.year_level === 'firstYear') {
            this.requirementsF[index] = {
              ...this.requirementsF[index],
              excelFilename: this.editFlag ? res.data?.data?.filename : res.data?.filename,
              excelPath: this.editFlag ? res.data?.data?.path : res.data?.path
            };
          } else {
            this.requirements[index] = {
              ...this.requirements[index],
              excelFilename: this.editFlag ? res.data?.data?.filename : res.data?.filename,
              excelPath: this.editFlag ? res.data?.data?.path : res.data?.path
            };
          }
          
          document.getElementById('excel-upload').value = '';
          this.file = '';
          this.fileUploadLoader = false;
          this.refreshApplication();
          toastr.success('Excel file uploaded successfully');
        })
        .catch((error) => {
          $('#progress-bar-modal').modal('hide');
          this.fileUploadLoader = false;
          toastr.error(error.response?.data?.message || 'Error uploading Excel file');
        });
    },

    hasExcelFileF(req) {
      return req.id === 12 && req.excelFilename && req.excelPath;
    },

    formatPhoneNumber() {
      // Remove any non-digit characters
      let phone = this.form.phone_no.replace(/\D/g, '');
      
      // If number doesn't start with 0, add it
      if (phone && !phone.startsWith('0')) {
        phone = '0' + phone;
      }
      
      // Ensure it doesn't exceed 11 digits
      phone = phone.slice(0, 11);
      
      this.form.phone_no = phone;
    },

    searchApplication() {
      if (!this.searchAppNo) return;

      this.searching = true;
      
      const parts = this.searchAppNo.split('CHED13-TOSF-');
      const id = parts[1];

      if (!id) {
        toastr.error('Invalid application number format');
        this.searching = false;
        return;
      }

      axios.get(`api/tosf/application-search/${id}`)
        .then(response => {
          this.temp_requirementsF = response.data.documents;
          console.log('CHECK DATA', response.data);

          if (response?.data?.application) {
            // Load the existing application data
            this.editFlag = true;
            this.form.id = response.data.application.id;
            this.form.hei_code = response.data.application.hei_code;
            this.form.academic_year = response.data.application.academic_year;
            this.form.email = response.data.application.email;
            this.form.contact_person = response.data.application.contact_person;
            
            // Format phone number when loading existing data
            this.form.phone_no = response.data.application.phone_no;
            this.formatPhoneNumber();
            
            this.year_level = response.data.application.type_increase;
            this.hei = this.heis.find(h => h.code === response.data.application.hei_code) || '';

            this.getRequirements();
            this.getRequirementsFirst();
            this.nextStep();

            toastr.success('Application found');
          } else {
            toastr.error('Application not found');
          }
        })
        .catch(error => {
          console.error('Error searching application:', error);
          toastr.error('Failed to search application');
        })
        .finally(() => {
          this.searching = false;
        });
    },
    refreshApplication() {
      if (!this.searchAppNo) return;

      this.searching = true;
      
      const parts = this.searchAppNo.split('CHED13-TOSF-');
      const id = parts[1];

      if (!id) {
        toastr.error('Invalid application number format');
        this.searching = false;
        return;
      }

      axios.get(`api/tosf/application-search/${id}`)
        .then(response => {

          this.temp_requirementsF = response.data.documents;

          if(this.year_level == 'firstYear') {
            if(this.editFlag) {
                this.requirementsF = this.temp_requirementsF;
            } else {
              if (this.isValidatedF()) {
                this.currentStep++;
              }
            }
          } else {
            if(this.editFlag) {
              this.requirements = this.temp_requirementsF;
            } else {
              if (this.isValidated()) {
                this.currentStep++;
              }
            }
          }


        })
        .catch(error => {
          console.error('Error searching application:', error);
          toastr.error('Failed to search application');
        })
        .finally(() => {
          this.searching = false;
        });
    },
  },
  mounted() {
    this.getHeis();
    
    this.dateSubmitted = new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
  },
  computed: {
    isValidEmail() {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return emailRegex.test(this.form.email);
    },
    isValidPhone() {
      return this.form.phone_no.length === 11 && this.form.phone_no.startsWith('0');
    },
  },
};
</script>

<style scoped>
ol li {
  padding-bottom: 10px;
  font-size: 15px;
}

.in-search {
  min-width: 300px;
}

.modal-dialog {
  max-width: 500px;
}
</style>
